import React, { useCallback, useState } from "react";
import { useIntl } from "gatsby-plugin-intl";
import { Container } from "react-bootstrap";
import styled from "styled-components";
import Slider from "react-slick";
import { getImage, GatsbyImage } from "gatsby-plugin-image";

import Arrow from "../../images/arrow.inline.svg";

const ArrowBtn = styled.button`
  background: transparent;
  border: none;
  &:first-of-type {
    transform: rotate(180deg);
  }
  &.hidden {
    opacity: 0;
    pointer-events: none;
  }
  svg {
    width: 50px;
    @media (min-width: 768px) {
      width: 80px;
    }
    height: auto;
  }
`;

const Item = styled.div`
  .icon {
    width: 100%;
    max-width: 200px;
  }
`;

const StyledSlider = styled(Slider)`
  overflow: hidden;
  .slick-list {
    margin: 0 -40px;
  }
  .slick-slide > div {
    padding: 0 40px;
  }
`;

const Methodology = ({ items = [] }) => {
  const intl = useIntl();
  const [slider, setSlider] = useState(null);
  const [currentSlide, setCurrentSlide] = useState(0);
  const sliderRef = useCallback(node => {
    if (node !== null) {
      setSlider(node);
    }
  }, []);
  console.log(slider);

  const sliderSettings = {
    slidesToShow: 3,
    arrows: false,
    infinite: false,
    beforeChange: (_oldIndex, newIndex) => {
      setCurrentSlide(newIndex);
    },
    responsive: [
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };

  return (
    <Container className="mt-5 pt-5">
      <div className="d-flex align-items-center justify-content-between mb-5">
        <ArrowBtn
          className={currentSlide === 0 ? "hidden" : ""}
          onClick={() => {
            if (slider) {
              slider.slickPrev();
            }
          }}
        >
          <Arrow />
        </ArrowBtn>
        <h2 className="h4 text-center mb-0">
          {intl.formatMessage({ id: "methodology" })}
        </h2>
        <ArrowBtn
          className={`d-none d-md-block ${
            slider &&
            Math.round(
              slider.innerSlider.state.slideCount / slider.props.slidesToShow
            ) === currentSlide
              ? "hidden"
              : ""
          }`}
          onClick={() => {
            if (slider) {
              slider.slickNext();
            }
          }}
        >
          <Arrow />
        </ArrowBtn>
        <ArrowBtn
          className={`d-md-none ${
            slider && currentSlide + 1 >= slider.innerSlider.state.slideCount
              ? "hidden"
              : ""
          }`}
          onClick={() => {
            if (slider) {
              slider.slickNext();
            }
          }}
        >
          <Arrow />
        </ArrowBtn>
      </div>
      <StyledSlider {...sliderSettings} ref={sliderRef}>
        {items.map(item => {
          const img = getImage(item.icon?.localFile?.childImageSharp);

          return (
            <Item className="text-center" key={item.title1.text}>
              <div className="icon mb-5 mx-auto">
                <GatsbyImage image={img} alt={item.title1.text} />
              </div>
              <h4 className="h5 text-left">{item.subtitle.text}</h4>
              <h3 className="h4 mb-4 text-left">{item.title1.text}</h3>
              <p className="text-left">{item.description1.text}</p>
            </Item>
          );
        })}
      </StyledSlider>
    </Container>
  );
};

export default Methodology;
