import React, { useContext } from "react";
import styled from "styled-components";
import { useIntl } from "gatsby-plugin-intl";
import { Container, Row, Col } from "react-bootstrap";
import { RichText } from "prismic-reactjs";
import { GatsbyImage, getImage } from "gatsby-plugin-image";

import { FormModalContext } from "../../providers/FormModalProvider";
import { addGifToContent } from "../../utils";

const TopSection = styled.div`
  .container {
    @media (min-width: 992px) {
      //max-width: calc(100% - ((100% - 960px) / 2));
      max-width: calc(960px + ((100% - 960px) / 2));
      margin-right: 0;
    }
    @media (min-width: 1200px) {
      //max-width: calc(100% - ((100% - 1140px) / 2));
      max-width: calc(1140px + ((100% - 1140px) / 2));
    }
  }
  .cover-wrapper {
    width: 45%;
    right: 0;
    bottom: 0;
    top: 0;
    background: lightgray;
    height: 400px;
  }
`;

const Button = styled.button`
  background-color: #000;
  color: #fff;
  padding: 15px 30px;
  border: none;
  transition: all 0.3s ease-in-out;
  &:hover {
    opacity: 0.6;
  }
`;

const Description = ({ title, description, image }) => {
  const intl = useIntl();
  const { setModalState } = useContext(FormModalContext);
  const img = getImage(image?.localFile?.childImageSharp);
  const serializer = addGifToContent();

  return (
    <TopSection className="mt-5 position-relative">
      <Container>
        <Row className="align-items-center">
          <Col lg={4} data-sal="slide-up" data-sal-delay={300}>
            <h1 className="h4 mb-5">{title}</h1>
            <div className="description mb-5">
              <RichText render={description} htmlSerializer={serializer} />
            </div>
            <Button
              onClick={() => setModalState({ type: "project", isOpen: true })}
            >
              ({intl.formatMessage({ id: "start_project" }).toLowerCase()})
            </Button>
          </Col>
          <Col
            lg={7}
            className="ml-auto pr-lg-0 mt-4 mt-lg-0"
            data-sal="slide-up"
            data-sal-delay={400}
          >
            <GatsbyImage image={img} alt={image?.alt || ""} />
          </Col>
        </Row>
      </Container>
    </TopSection>
  );
};

export default Description;
