import React from "react";
import { graphql } from "gatsby";

import Layout from "../components/Layout";
import Description from "../components/single-service/Description";
import Methodology from "../components/single-service/Methodology";

const Service = ({ data }) => {
  const { prismicServicios } = data;

  return (
    <Layout>
      <Description
        title={prismicServicios?.data?.title?.text}
        description={prismicServicios?.data?.description?.raw}
        image={prismicServicios?.data?.cover_image}
      />
      <Methodology items={prismicServicios?.data?.methodology} />
    </Layout>
  );
};

export const query = graphql`
  query($language: String!, $uid: String!) {
    prismicServicios(uid: { eq: $uid }, lang: { eq: $language }) {
      data {
        title {
          text
        }
        description {
          raw
        }
        cover_image {
          alt
          localFile {
            childImageSharp {
              gatsbyImageData
            }
          }
        }
        methodology {
          icon {
            localFile {
              childImageSharp {
                gatsbyImageData
              }
            }
          }
          subtitle {
            text
          }
          title1 {
            text
          }
          description1 {
            text
          }
        }
      }
    }
  }
`;

export default Service;
